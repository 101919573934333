import React from 'react';
import InitialContent from './InitialContent';
import ParticlesComponent from './ParticlesComponent';
import '../css/Home.css';

function Home() {
  return (
    <div className='telaHome'>
      <ParticlesComponent />
      <InitialContent />
    </div>
  );
}

export default Home;
