import React, { useState } from 'react';
import '../css/Team.css';
import Castelar from '../../img/Team/Castelar/Castelar.png';
import Gabriel from '../../img/Team/Gabriel/Gabriel.jpg';
import Heitor from '../../img/Team/Heitor/Heitor.png';
import Igor from '../../img/Team/Igor/Igor.png';
import Arthur from '../../img/Team/Arthur/Arthur.png';
import Jesse from '../../img/Team/Jesse/Jesse.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Team() {
  const [selectedMember, setSelectedMember] = useState(null);

  const team = [
    { nome: 'João Pedro Castelar', funcao: 'Frontend', foto: Castelar, intro: 'Formado em Ciência da Computação. Com uma paixão por criar interfaces de usuário intuitivas e visualmente atraentes, tenho experiência frameworks modernos como React e Vue.js. Estou sempre em busca de novos desafios que me permitam aplicar minhas habilidades e aprender novas tecnologias para entregar soluções inovadoras e eficientes. Meu objetivo é transformar ideias em experiências digitais excepcionais que encantem os usuários e atendam às necessidades dos clientes.', instagram: 'https://www.instagram.com/dixx_castelar/', linkedin: 'https://www.linkedin.com/in/joão-pedro-da-silva-castelar-3b7699203/' },
    { nome: 'Gabriel Valle', funcao: 'Backend', foto: Gabriel, intro: 'Sou um programador dedicado e versátil. Estou sempre em busca de soluções criativas e eficientes em qualquer área que trabalho. Minha abordagem é caracterizada pela disposição contínua de aprender e evoluir, adaptando às novas tecnologias e desafios para melhorar constantemente minhas habilidades e resultados.', instagram: 'https://www.instagram.com/gabrielbrq_/', linkedin: 'https://www.linkedin.com/in/gabrielmedvalle/' },
    { nome: 'Heitor Pelizaro', funcao: 'QA', foto: Heitor, intro: 'Olá, me chamo Heitor Pelizaro e atualmente estou no último ano do curso de Ciência da Computação no IFTM - Campus Ituiutaba. Sou integrante do PET Computação/ADS desde a sua criação em 2021 e junto a eles obtive muitas experiências, desde a organização de eventos até ministrar aulas de robótica e ser monitor de Mini Cursos que nós mesmos criamos. Ademais fui finalista estadual certificado do Desafio Jovem Empreendedor do SEBRAE e possuo experiência tanto na gestão de equipes e gerenciamento de pessoas quanto em entender e compreender as necessidades de cada um.', instagram: 'https://www.instagram.com/heitor.pelizaro', linkedin: 'https://www.linkedin.com/in/heitor-pelizaro-1b9106211/' },
    { nome: 'Igor Costa Lima', funcao: 'Design', foto: Igor, intro: 'Formado em Técnico em informática, Cursando o 8º Período de Ciência da Computação e Integrante do PET (Programa de Extensão e Tutorial) a 2 anos pelo IFTM, desenvolvendo diversas atividades como projetos de pesquisa e tutorias. Acredito que estou pronto para auxiliar todos aqueles que buscam empreender, iniciar ou aprimorar seus negócios, tanto no Brasil quanto ao redor do mundo.', instagram: 'https://www.instagram.com/igor.limaa/', linkedin: 'https://www.linkedin.com/in/igor-lima-306b09182/' },
    { nome: 'Arthur Pelizaro', funcao: 'Full-Stack', foto: Arthur, intro: 'Sou um Desenvolvedor Full Stack formado em Análise de Sistemas de Computação pelo Instituto Federal do Triângulo Mineiro (IFTM), com mais de cinco anos de experiência em projetos de software nas áreas de web, mobile e APIs RESTful. Tenho conhecimentos avançados em linguagens como Java, PHP, JavaScript, HTML, CSS e AngularJS, além de competências interpessoais, como comunicação, organização e trabalho em equipe.', instagram: 'https://www.instagram.com/arthur_pelizaro/', linkedin: 'https://www.linkedin.com/in/arthur-pelizaro-2b5b49235/'},
    { nome: 'Jessé Gabriel', funcao: 'Analista de Dados', foto: Jesse, intro: 'Sou apaixonado por tecnologia desde jovem e fundei a adclabs com o objetivo de oferecer soluções inovadoras que combinam criatividade e técnica. Tenho experiência em desenvolvimento web, jogos e aplicações, buscando sempre aprender e evoluir no campo da tecnologia. Meu foco é criar soluções que não só resolvam problemas, mas também inspirem e impulsionem o sucesso de nossos clientes.', instagram: 'https://www.instagram.com/jessesap/', linkedin: 'https://www.linkedin.com/in/jessesap' }
  ];

  const openModal = (member) => {
    setSelectedMember(member);
  };

  const closeModal = () => {
    setSelectedMember(null);
  };

  
  return (
    <div className='telaTeam'>
      <div>
        <h1>Nossa Equipe</h1>
      </div>
      <div className='cardsGrid'>
        {team.map((member, index) => (
          <div className='card' key={index}>
            <div className='perfil'>
              <img src={member.foto} alt={member.nome} />
              <h2>{member.nome}</h2>
              <p>{member.funcao}</p>
              <div className='social-links'>
                <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
              <button className='sobre-button' onClick={() => openModal(member)}>Sobre</button>
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <span className='close' onClick={closeModal}>&times;</span>
            <img src={selectedMember.foto} alt={selectedMember.nome} />
            <h2>{selectedMember.nome}</h2>
            <p>{selectedMember.intro}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Team;
