import React from 'react';
import '../css/Home.css';
import pessoa from '../../img/Home/pessoa.gif';
import Carousel from './ServiceCarousel';

function InitialContent() {
  return (
    <div className='content'>
      <div className="heading">
        <div className="heading-content">
          <h1>Tecnologia que Impulsiona o Sucesso</h1>
          <h4>Nossas soluções personalizadas e escaláveis maximizam a eficiência, otimizam processos e garantem uma experiência de usuário impecável, colocando sua empresa na vanguarda da inovação.</h4>
        </div>
        <img src={pessoa} alt="Pessoa usando computador" />
      </div>

      <div className='wrapperScroller'>
        <div className='item item1'>Inovação</div>
        <div className='item item2'>Futuro</div>
        <div className='item item3'>Eficiência</div>
        <div className='item item4'>Excelência</div>
        <div className='item item5'>Qualidade</div>
        <div className='item item6'>Inteligência</div>
        <div className='item item7'>Sucesso</div>
      </div>

      <div className='whoweare'>
        <h3>Quem somos</h3>
        <p>Na adclabs, somos apaixonados por tecnologia e inovação. Fundada por uma equipe de visionários e especialistas em tecnologia, nossa missão é transformar ideias em soluções digitais robustas e eficientes.</p>
        <p>Com um portfólio diversificado que abrange desde o desenvolvimento web até a criação de jogos, aplicações e análise de dados, estamos sempre à frente das tendências tecnológicas, oferecendo soluções personalizadas e de alta qualidade para nossos clientes.</p>
        <p>Nosso compromisso é entregar excelência em cada projeto, ajudando empresas e indivíduos a alcançar seus objetivos através da tecnologia. Acreditamos que a inovação está no coração de tudo o que fazemos e estamos constantemente explorando novas formas de criar valor e fazer a diferença no mundo digital.</p>
        <p>Junte-se a nós nessa jornada de transformação digital e descubra como podemos ajudar a impulsionar o seu sucesso.</p>
      </div>

      <div className='services-container'>
        <h4 className="services-subtitle">Nossos serviços</h4>
        <h2 className='services-title'>SERVIÇOS OFERECIDOS</h2>
        <Carousel />
      </div>
    </div>
  );
}

export default InitialContent;
