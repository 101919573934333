import React, { useState, useEffect } from 'react';
import '../css/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    website: '',
    subject: '',
    message: ''
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const { name, email, subject, message } = formData;
    const isValid = name.trim() && email.trim() && subject.trim() && message.trim();
    setIsFormValid(isValid);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('Mensagem enviada com sucesso!');
        setFormData({ name: '', email: '', website: '', subject: '', message: '' });
      } else {
        alert('Falha ao enviar a mensagem.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao enviar a mensagem.');
    }
  };

  const generateMessage = () => {
    return `Olá, meu nome é ${formData.name} e estou aqui para falar sobre ${formData.subject}.\n\n${formData.message}`;
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contatos:</h2>
        <p>Entre em contato com a nossa empresa através das seguintes plataformas.</p>
        <div className="contact-details">
          <div className="contact-detail">
            <i className="fa-solid fa-phone"></i>
            <div>
              <h3>Telefone</h3>
              <p>+55 (16) 99107-7877</p>
            </div>
          </div>
          <div className="contact-detail">
            <i className="fa-solid fa-envelope"></i>
            <div>
              <h3>Email</h3>
              <p>contato@adclabs.com.br</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <h2>Você possui alguma dúvida?</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Nome"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="text"
            name="website"
            placeholder="Website (Opcional)"
            value={formData.website}
            onChange={handleChange}
          />
          <input
            type="text"
            name="subject"
            placeholder="Assunto"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Mensagem..."
            value={formData.message}
            onChange={handleChange}
            required
          />
          <div className="buttons-container">
            <button type='button' onClick={() => window.location.href = `mailto:contato@adclabs.com.br?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(generateMessage())}`} disabled={!isFormValid}>
              Enviar E-mail
            </button>
            <button type='button' onClick={() => window.location.href = `https://wa.me/5516991077877?text=${encodeURIComponent(generateMessage())}`} disabled={!isFormValid}>
              Enviar WhatsApp
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
