import React from 'react';
import '../css/Tools.css';
import ReactLogo from '../../img/Tools/React-logo.png';
import VueLogo from '../../img/Tools/Vue-logo.png';
import NodeLogo from '../../img/Tools/Node-logo.png';
import AWSLogo from '../../img/Tools/Aws-logo.png';
import MySQLLogo from '../../img/Tools/Mysql-logo.png';
import PostgreLogo from '../../img/Tools/Postgre-logo.png';
import SQLServerLogo from '../../img/Tools/SqlServer-logo.png';
import UnityLogo from '../../img/Tools/Unity-logo.png';
import IllustratorLogo from '../../img/Tools/Ilustrator-logo.png';
import CanvaLogo from '../../img/Tools/Canva-Logo.png';
import FigmaLogo from '../../img/Tools/Figma-logo.png';
import PythonLogo from '../../img/Tools/Python-logo.png';
import JavaLogo from '../../img/Tools/Java-logo.png';
import JavascriptLogo from '../../img/Tools/Javascript-Logo.png';
import CppLogo from '../../img/Tools/Cpp-logo.png';
import RubyLogo from '../../img/Tools/Ruby-logo.png';

const tools = [
  {
    category: 'Linguagens de programação',
    items: [

      { img: PythonLogo, name: 'Python' },
      { img: JavaLogo, name: 'Java' },
      { img: JavascriptLogo, name: 'JAVASCRIPT' },
      { img: CppLogo, name: 'c++' },
      { img: RubyLogo, name: 'Ruby' },
    ],
  },
  {
    category: 'Desenvolvimento Web',
    items: [
      { img: ReactLogo, name: 'React' },
      { img: VueLogo, name: 'Vue' },
      { img: NodeLogo, name: 'Node' },
      { img: AWSLogo, name: 'AWS' },
    ],
  },
  {
    category: 'Banco de dados',
    items: [
      { img: MySQLLogo, name: 'MySQL' },
      { img: PostgreLogo, name: 'PostgreSQL' },
      { img: SQLServerLogo, name: 'SQLServer' },
    ],
  },
  {
    category: 'Desenvolvimento de jogos',
    items: [{ img: UnityLogo, name: 'Unity' }],
  },
  {
    category: 'Design',
    items: [
      { img: IllustratorLogo, name: 'Illustrator' },
      { img: CanvaLogo, name: 'Canva' },
      { img: FigmaLogo, name: 'Figma' },
    ],
  },
];

function Tools() {
  return (
    <div className='tool-body'>
      <h1>Ferramentas que Usamos</h1>
      {tools.map((toolCategory) => (
        <div key={toolCategory.category} className='tool-container'>
          <h2 className='tool-title'>{toolCategory.category}:</h2>
          <div className='tool-content'>
            {toolCategory.items.map((tool) => (
              <div key={tool.name} className='tool-item'>
                <img src={tool.img} alt={`${tool.name} logo`} />
                <h4>{tool.name}</h4>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Tools;
