import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';
import logo from '../../img/Home/LogoADC_3.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <div>
            <button type="button">
              <img src={logo} alt="Logo" />
              <h1>ADCLabs</h1>
            </button>
          </div>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`navbar-nav ${isOpen ? "open" : ""}`}>
          <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
          <li className="nav-item"><Link to="/team" className="nav-link">Nossa Equipe</Link></li>
          <li className="nav-item"><Link to="/tools" className="nav-link">Ferramentas</Link></li>
          <li className="nav-item"><Link to="/contact" className="nav-link">Contate-nos</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
