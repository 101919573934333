import React, { useEffect, useRef } from 'react';
import consultoria from '../../img/Home/consultoria.png';
import design from '../../img/Home/design.png';
import mkt from '../../img/Home/mkt-digital.png';
import desenvolvimento from '../../img/Home/web.png';
import '../css/Services.css';

function Carousel() {
    const wrapperRef = useRef(null);
    const carouselRef = useRef(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const startScrollLeft = useRef(0);
    const timeoutId = useRef(null);
    const left = '<';
    const right = '>';

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const carousel = carouselRef.current;
        const firstCardWidth = carousel.querySelector(".card").offsetWidth;
        const arrowBtns = wrapper.querySelectorAll("i");
        const carouselChildren = [...carousel.children];

        let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

        carouselChildren.slice(-cardPerView).reverse().forEach(card => {
            carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
        });

        carouselChildren.slice(0, cardPerView).forEach(card => {
            carousel.insertAdjacentHTML("beforeend", card.outerHTML);
        });

        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");

        arrowBtns.forEach(btn => {
            btn.addEventListener("click", () => {
                carousel.scrollLeft += btn.id === "left" ? -firstCardWidth : firstCardWidth;
            });
        });

        const dragStart = (e) => {
            isDragging.current = true;
            carousel.classList.add("dragging");
            startX.current = e.pageX;
            startScrollLeft.current = carousel.scrollLeft;
        };

        const dragging = (e) => {
            if (!isDragging.current) return;
            carousel.scrollLeft = startScrollLeft.current - (e.pageX - startX.current);
        };

        const dragStop = () => {
            isDragging.current = false;
            carousel.classList.remove("dragging");
        };

        const infiniteScroll = () => {
            if (carousel.scrollLeft === 0) {
                carousel.classList.add("no-transition");
                carousel.scrollLeft = carousel.scrollWidth - (2 * carousel.offsetWidth);
                carousel.classList.remove("no-transition");
            } else if (Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
                carousel.classList.add("no-transition");
                carousel.scrollLeft = carousel.offsetWidth;
                carousel.classList.remove("no-transition");
            }

            clearTimeout(timeoutId.current);
            if (!wrapper.matches(":hover")) autoPlay();
        };

        const autoPlay = () => {
            if (window.innerWidth < 800) return;
            timeoutId.current = setTimeout(() => carousel.scrollLeft += firstCardWidth, 2500);
        };
        autoPlay();

        carousel.addEventListener("mousedown", dragStart);
        carousel.addEventListener("mousemove", dragging);
        document.addEventListener("mouseup", dragStop);
        carousel.addEventListener("scroll", infiniteScroll);
        wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId.current));
        wrapper.addEventListener("mouseleave", autoPlay);

        return () => {
            carousel.removeEventListener("mousedown", dragStart);
            carousel.removeEventListener("mousemove", dragging);
            document.removeEventListener("mouseup", dragStop);
            carousel.removeEventListener("scroll", infiniteScroll);
            wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutId.current));
            wrapper.removeEventListener("mouseleave", autoPlay);
        };
    }, []);

    return (
        <div className="wrapper" ref={wrapperRef}>
            <i id="left" className="fa-solid fa-angle-left">{left}</i>
            <ul className="carousel" ref={carouselRef}>
                <li className="card">
                    <div className="img"><img src={mkt} alt="img" draggable="false" /></div>
                    <h2>Marketing digital</h2>
                    <span>Desenvolvemos planos personalizados para aumentar sua visibilidade online, gerar leads e impulsionar suas conversões.</span>
                </li>
                <li className="card">
                    <div className="img"><img src={mkt} alt="img" draggable="false" /></div>
                    <h2>Gestão de conteúdo</h2>
                    <span>Produzimos conteúdo informativo, relevante e envolvente para seus canais online.</span>
                </li>
                <li className="card">
                    <div className="img"><img src={design} alt="img" draggable="false" /></div>
                    <h2>Design gráfico</h2>
                    <span>Projetamos interfaces intuitivas e amigáveis para seus softwares e websites.</span>
                </li>
                <li className="card">
                    <div className="img"><img src={desenvolvimento} alt="img" draggable="false" /></div>
                    <h2>Desenvolvimento</h2>
                    <span>Criamos soluções customizadas que atendem às necessidades específicas do seu negócio, desde sistemas de gestão, plataformas de e-commerce e até jogos.</span>
                </li>
                <li className="card">
                    <div className="img"><img src={consultoria} alt="img" draggable="false" /></div>
                    <h2>Consultoria</h2>
                    <span>Auxiliamos na definição de objetivos e no desenvolvimento de um plano estratégico de TI alinhado com as metas do seu negócio.</span>
                </li>
            </ul>
            <i id="right" className="fa-solid fa-angle-right">{right}</i>
        </div>
    );
}

export default Carousel;
