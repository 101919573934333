import React from 'react';
import wpp from '../../img/whatsapp.png';
import insta from '../../img/instagram.png';
import logo from '../../img/Home/LogoADC_3.png';
import linkedin from '../../img/linkedin.png';
import '../css/Footer.css'

function Footer() {
  return (
    <footer className="footer">
      <div className="upper-part">
        <div className="right-part">
          <p>Entre em contato para podermos te ajudar com seu negócio através da tecnologia.</p>
          <div className="footer-tel-title">
            Telefone: +55 (16) 99107-7877
          </div>
          <div className="footer-tel-title">
            Email: contato@adclabs.com.br
          </div>
        </div>
        
        <div className="left-part">
          <img src={logo} alt="ADCLabs logo" />
          <p>ADCLabs: Tecnologia que Impulsiona o Sucesso!</p>
        </div>


      </div>
      <div className="lower-part">
        <p>Todos os direitos reservados - 2024.</p>
      </div>
    </footer>
  );
}

export default Footer;
