import React from 'react';

function Clients() {
  return (
    <div>
      <h1>Nossos Clientes</h1>
    </div>
  );
}

export default Clients;
